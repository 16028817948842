.App {
  text-align: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

img {
  position: fixed;
  top: 0;
  left: 0;
}
